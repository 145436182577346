import { useState, useEffect } from 'react';

const useAuth = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const TGA_SERVICE_URL = process.env.REACT_APP_TGA_SERVICE_URL;

  useEffect(() => {
    // const token = localStorage.getItem('authToken');

    // if (token) {
    //   setLoggedIn(true); // User is logged in if token exists
    // }

    const query = new URLSearchParams(window.location.search);
    const urlToken = query.get('token');
    if (urlToken) {
      localStorage.setItem('authToken', urlToken);
      setLoggedIn(true);
      window.history.replaceState({}, document.title, '/');
    }
  }, []);

  const handleLogin = () => {
    window.location.href = `${TGA_SERVICE_URL}/auth/google`;
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setLoggedIn(false);
  };

  return { loggedIn, handleLogin, handleLogout };
};

export default useAuth;
