import React from 'react';
import RenderTable from './RenderTable';
import { Box } from '@mui/material';

const RenderTranscriptions = ({ input }: any) => {

  if (!input?.response?.transcriptions) return null;

  const { transcriptions } = input.response;
  const { verifiedTranscription } = input;
  const tables = [];
  const tableTranscriptions = [
    {
      lineDetails: verifiedTranscription,
      source: {
        provider: 'manual',
        service: 'verified'
      }
    },
    ...transcriptions
]

  for (let i = 0; i < tableTranscriptions.length; i++) {
    let lineDetails = tableTranscriptions[i].lineDetails;

    tables.push(<RenderTable key={i} lineDetails={lineDetails} label={`'${tableTranscriptions[i].source.provider} ${tableTranscriptions[i].source.service}'`} />);
  }

  return <Box>{tables}</Box>;
};

export default RenderTranscriptions;
