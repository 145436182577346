import axios from 'axios';

export const assessTranscription = async (input: {selectedDocument: number, verifiedTranscription: any, useHandwritingSample: boolean, rosterMemberId: number | null}) => {
  const { selectedDocument, verifiedTranscription } = input;
  const token = localStorage.getItem('authToken');
  const TGA_SERVICE_URL = process.env.REACT_APP_TGA_SERVICE_URL;
  const body = {
    verifiedTranscription,
    documentId: selectedDocument
  };

  return axios.post(`${TGA_SERVICE_URL}/api/assess_transcription`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const listDocuments = async () => {
  const token = localStorage.getItem('authToken');
  const TGA_SERVICE_URL = process.env.REACT_APP_TGA_SERVICE_URL;

  return axios.get(`${TGA_SERVICE_URL}/documents`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const listRosters = async () => {
  const token = localStorage.getItem('authToken');
  const TGA_SERVICE_URL = process.env.REACT_APP_TGA_SERVICE_URL;

  return axios.get(`${TGA_SERVICE_URL}/rosters`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const listRosterMembers = async (rosterId: number) => {
  const token = localStorage.getItem('authToken');
  const TGA_SERVICE_URL = process.env.REACT_APP_TGA_SERVICE_URL;

  return axios.get(`${TGA_SERVICE_URL}/roster/members`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
        roster_id: rosterId,
        require_handwriting_sample: 'true'
    }
  });
};

export const importDocument = async (driveDocLink: string) => {
  const token = localStorage.getItem('authToken');
  const TGA_SERVICE_URL = process.env.REACT_APP_TGA_SERVICE_URL;
  const body = {
    importMethod: 'GOOGLE_DRIVE_FILE',
    documentImportContext: { URL: driveDocLink },
    documentTypeInternal: 'PDF',
    verifiedTranscription: null,
    reportedDocumentContentType: 'ASSIGNMENT',
    textFormat: 'HANDWRITTEN',
    assignmentId: null,
    useHandwritingSample: 'false',
    rosterMemberId: null
  };

  return axios.post(`${TGA_SERVICE_URL}/api/import_pdf`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};