import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab
} from '@mui/material';
import { ImageSearch } from '@mui/icons-material';
import useAuth from './hooks/useAuth';
import { assessTranscription, importDocument, listRosterMembers, listRosters, listDocuments } from './services/apiService';
import RenderTranscriptions from './components/Table/RenderTranscriptions';

const evaTestFileDriveDocUrl = 'https://drive.google.com/file/d/18nfPE0YVFIG06uZXlDwrZoT_qrYm15RP/view?usp=sharing';
const evaTestFileTranscription = `Eva\n1. High school musicail\n2. First get 2 pieces of bread\nand get some Jelly and peanut butter.\nSpread both of them on & stick them\ntoghether vua la.\n3. it’s black & white: Its a flightless\nbird, Likes to belly slide.`;

const App: React.FC = () => {
  const [tabValue, setTabValue] = useState(0); // For handling tabs
  const [driveDocLink, setDriveDocLink] = useState(evaTestFileDriveDocUrl);
  const [driveDocImportLink, setDriveDocImportLink] = useState(`https://drive.google.com/file/d/1USGRKSJMgzm86goaXGl-_rmDCBJHiXsX/view?usp=sharing`);
  const [verifiedTranscriptionInput, setVerifiedTranscriptionInput] = useState(evaTestFileTranscription);
  const [renderTranscriptionInput, setRenderTranscriptionInput] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [responseError, setResponseError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  // const [useHandwritingSample, setUseHandwritingSample] = useState(false); // Checkbox state
  // const [rosters, setRosters] = useState<{ id: number, label: string }[]>([]); // Rosters state
  const [documents, setDocuments] = useState<{ s3Key: string, id: number }[]>([]);
  // const [selectedRoster, setSelectedRoster] = useState<number | null>(null); // Selected roster
  const [selectedDocument, setSelectedDocument] = useState<number | null>(null); // Selected roster
  // const [rosterMembers, setRosterMembers] = useState<{ id: number, displayName: string }[]>([]); // Roster members
  // const [selectedStudent, setSelectedStudent] = useState<number | null>(null); // Selected student
  const { loggedIn, handleLogin, handleLogout } = useAuth();

  // useEffect(() => {
  //   const fetchRosters = async () => {
  //     try {
  //       const res = await listRosters();
  //       setRosters(res.data.rosters);
  //     } catch (error) {
  //       console.error('Error fetching rosters:', error);
  //     }
  //   };

  //   fetchRosters();
  // }, []);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const res = await listDocuments();
        setDocuments(res.data.fileNames);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, []);

  // useEffect(() => {
  //   const fetchRosterMembers = async () => {
  //     if (selectedRoster && useHandwritingSample) {
  //       try {
  //         const res = await listRosterMembers(selectedRoster);
  //         const mappedRosterMembers = res.data.roster_members.map((member: any) => ({
  //           displayName: `${member.first_name} ${member.last_name}`,
  //           id: member.id
  //         }));
  //         setRosterMembers(mappedRosterMembers);
  //       } catch (error) {
  //         console.error('Error fetching roster members:', error);
  //       }
  //     }
  //   };

  //   fetchRosterMembers();
  // }, [selectedRoster, useHandwritingSample]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const verifiedTranscription = verifiedTranscriptionInput
        .split('\n')
        .filter(line => line.trim() !== '')
        .map(text => ({ text, requiresReview: false }));

      const res = await assessTranscription({ selectedDocument: selectedDocument!, verifiedTranscription, useHandwritingSample: false, rosterMemberId: null });
      setResponse(res.data.data);
      setRenderTranscriptionInput({ verifiedTranscription, response: res.data.data });
      setImageUrl(res.data?.data?.fileUrl || '');
      setResponseError(res.data?.error || '');
    } catch (error) {
      console.error('Error processing request:', error);
      setResponseError('Error processing the transcription.');
    } finally {
      setLoading(false);
    }
  };
  const handleImportDocument = async () => {
    const res = await importDocument(driveDocImportLink);
    console.log(res);
    console.log(res?.data?.message);
    const messageText = res?.data?.message || "something went wrong. Stuff still be goin' wrong all the damn time"
    setMessage(messageText)
  };
  const handleViewImage = () => {
    if (imageUrl) window.open(imageUrl, '_blank');
  };

  const isSubmitDisabled = () => {
    if (!driveDocLink || !verifiedTranscriptionInput) return true;
    // if (useHandwritingSample && (!selectedRoster || !selectedStudent)) return true;
    return false;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    if (newValue === 0) {
      setResponse(null);  // Clear the response data
      setRenderTranscriptionInput(null);  // Clear the rendered transcription input
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          THE GRADER AIDER
        </Typography>

        {!loggedIn ? (
          <Button variant="contained" color="primary" onClick={handleLogin} fullWidth>
            Log in with Google
          </Button>
        ) : (
          <>
            {/* Tabs for Import Files and Assess Transcriptions */}
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs">
              <Tab label="Import Files" />
              <Tab label="Assess Transcriptions" />
            </Tabs>

            {tabValue === 0 && (
              <Box mt={2}>
                <form>

                  <TextField
                    fullWidth
                    label="Drive Share Link"
                    variant="outlined"
                    value={driveDocImportLink}
                    onChange={(e) => setDriveDocImportLink(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      // Handle Axios call here for "Import Files"
                      console.log("Drive Share Link submitted:", driveDocImportLink);
                      handleImportDocument()
                    }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </form>
                {message && <p>{message}</p>}
              </Box>
            )}

            {tabValue === 1 && (
              !loading && (
                <form onSubmit={handleSubmit}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Select Document</InputLabel>
                    <Select
                      value={selectedDocument ?? ''}
                      onChange={(e) => setSelectedDocument(Number(e.target.value))}
                    >
                      {documents?.length && documents.map((document) => (
                        <MenuItem key={document.id} value={document.id}>
                          {document.s3Key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* {useHandwritingSample && selectedRoster && (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel>Select Student</InputLabel>
                      <Select
                        value={selectedStudent ?? ''}
                        onChange={(e) => setSelectedStudent(Number(e.target.value))}
                      >
                        {rosterMembers.map((member) => (
                          <MenuItem key={member.id} value={member.id}>
                            {member.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )} */}

                  {/* <TextField
                    fullWidth
                    label="Drive doc link. Must be jpeg or png."
                    variant="outlined"
                    value={driveDocLink}
                    onChange={(e) => setDriveDocLink(e.target.value)}
                    sx={{ mb: 2 }}
                  /> */}
                  <TextField
                    fullWidth
                    label="Manual Transcription (for verifying if the automated transcription is accurate)"
                    variant="outlined"
                    value={verifiedTranscriptionInput}
                    onChange={(e) => setVerifiedTranscriptionInput(e.target.value)}
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<ImageSearch />}
                    disabled={isSubmitDisabled()}
                    fullWidth
                  >
                    Submit
                  </Button>
                </form>
              )
            )}

            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            )}

            {response && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Response:
                </Typography>
                <RenderTranscriptions input={renderTranscriptionInput} />
                <Button variant="outlined" color="secondary" onClick={handleViewImage} sx={{ mt: 2 }}>
                  View Student Quiz
                </Button>
              </Box>
            )}

            {responseError && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Response Error:
                </Typography>
                <Typography variant="body1" color="error">
                  {responseError}
                </Typography>
              </Box>
            )}

            <Button variant="contained" color="secondary" onClick={handleLogout} fullWidth sx={{ mt: 4 }}>
              Log Out
            </Button>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default App;
