import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';

// Helper function to determine the circle color based on accuracy score and review status
const getCircleColor = (accuracyScore: number, requiresReview: boolean) => {
  if (accuracyScore === null || accuracyScore === undefined) {
    return 'black'
  }
  if (accuracyScore > 96) {
    return 'green';
  } else if (requiresReview) {
    return 'yellow';
  } else {
    return 'red';
  }
};

const RenderTable = ({ lineDetails, label }: any) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h6">{label}</Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Text</TableCell>
            <TableCell>Accuracy Score</TableCell>
            <TableCell>Confidence</TableCell>
            <TableCell>Flagged for Review</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineDetails.map((line: any, index: number) => (
            <TableRow key={index}>
              <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    width: '16px', // Adjust size as needed
                    height: '16px',
                    borderRadius: '50%',
                    backgroundColor: getCircleColor(line.accuracyScore, line.requiresReview),
                    alignSelf: 'center',
                  }}
                />
              </TableCell>
              <TableCell>{line.text}</TableCell>
              <TableCell>{line.accuracyScore}</TableCell>
              <TableCell>{line.confidenceScore}</TableCell>
              <TableCell>{line.requiresReview ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default RenderTable;
